export const comments = {
  "ja": {
    "score": {
      "up": "生活習慣スコアは5月より%d点上がりました。変化が見られたのはどの項目ですか？<br>\n7月は急激な気温上昇に体がついていけず、熱中症になる方が多くいます。熱中症対策の基本は、<b>❝暑さから身を守ること❞</b>と<b>❝こまめな水分補給❞</b>です。帽子や日傘の活用、喉が渇く前の早めの水分補給で熱中症を予防しましょう！\n\n",
      "down": "生活習慣スコアは5月より%d点下がりました。変化が見られたのはどの項目ですか？<br>\n7月は急激な気温上昇に体がついていけず、熱中症になる方が多くいます。熱中症対策の基本は、<b>❝暑さから身を守ること❞</b>と<b>❝こまめな水分補給❞</b>です。帽子や日傘の活用、喉が渇く前の早めの水分補給で熱中症を予防しましょう！\n\n",
      "equal100": "生活習慣スコアは5月に引き続き%満点%です。素晴らしい！<br>\n7月は急激な気温上昇に体がついていけず、熱中症になる方が多くいます。熱中症対策の基本は、<b>❝暑さから身を守ること❞</b>と<b>❝こまめな水分補給❞</b>です。帽子や日傘の活用、喉が渇く前の早めの水分補給で熱中症を予防しましょう！\n\n",
      "equal": "生活習慣スコアは5月と同じ点数です。<br>\n7月は急激な気温上昇に体がついていけず、熱中症になる方が多くいます。熱中症対策の基本は、<b>❝暑さから身を守ること❞</b>と<b>❝こまめな水分補給❞</b>です。帽子や日傘の活用、喉が渇く前の早めの水分補給で熱中症を予防しましょう！\n\n"
    },
    "steps": {
      "up": "歩数スコアは5月より%d点上がりました。雨が多い時期に素晴らしい結果です！<br>\nWalk チーム対抗戦 2024 1stの効果はいかがでしょうか？<a href=\"https://go.andwell.jp/library/tag/9d2a9468-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>を参考にしながら、良い習慣は継続していきましょう！\n\n",
      "down": "歩数スコアは5月より%d点下がりました。<br>\nこまめに歩いても長時間歩いても、合計時間が同じならウォーキングの効果に変わりはありません。<a href=\"https://go.andwell.jp/library/tag/9d2a9468-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>を参考にしながら歩数を増やすヒントを見つけませんか？\n\n",
      "equal": "歩数スコアは5月と同じ点数です。<br>\nこまめに歩いても長時間歩いても、合計時間が同じならウォーキングの効果に変わりはありません。<a href=\"https://go.andwell.jp/library/tag/9d2a9468-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>を参考にしながら歩数を増やすヒントを見つけませんか？\n\n"
    },
    "exercise": {
      "up": "運動のスコアは5月より%d点上がりました。<br>\n運動は、暑さに体を慣らす暑熱順化（しょねつじゅんか）の効果的な方法です。暑さに慣れることは、熱中症予防につながります！\n\n",
      "down": "運動のスコアは5月より%d点下がりました。先月の自分を振り返ってみましょう。<br>\n運動は、暑さに体を慣らす暑熱順化（しょねつじゅんか）の効果的な方法です。暑さに慣れることは、熱中症予防につながります！\n\n",
      "equal": "運動のスコアは5月と同じ点数です。<br>\n運動は、暑さに体を慣らす暑熱順化（しょねつじゅんか）の効果的な方法です。暑さに慣れることは、熱中症予防につながります！\n\n"
    },
    "meal": {
      "up": "食事のスコアは5月より%d点上がりました。<br>\n気温が上がるとついつい冷たい食べ物を選んでしまいがちですが、冷たい物の食べ過ぎは胃腸の働きを弱くし食欲低下の原因となります。冷たい物は一気に食べず、少しずつ食べるのがおすすめです。\n\n",
      "down": "食事のスコアは5月より%d点下がりました。<br>\n気温が上がるとついつい冷たい食べ物を選んでしまいがちですが、冷たい物の食べ過ぎは胃腸の働きを弱くし食欲低下の原因となります。冷たい物は一気に食べず、少しずつ食べるのがおすすめです。\n\n",
      "equal": "食事のスコアは5月と同じ点数です。<br>\n気温が上がるとついつい冷たい食べ物を選んでしまいがちですが、冷たい物の食べ過ぎは胃腸の働きを弱くし食欲低下の原因となります。冷たい物は一気に食べず、少しずつ食べるのがおすすめです。\n\n"
    },
    "drinking": {
      "up": "飲酒のスコアは5月より%d点上がりました。<br>\n気温が上がりキンキンに冷えたお酒が美味しい季節がやってきますが、飲みすぎには注意が必要！<a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から、健康的なお酒の楽しみ方を考えましょう。\n\n",
      "down": "飲酒のスコアは5月より%d点下がりました。<br>\n気温が上がりキンキンに冷えたお酒が美味しい季節がやってきますが、飲みすぎには注意が必要！<a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から、健康的なお酒の楽しみ方を考えましょう。\n\n",
      "equal": "飲酒のスコアは5月と同じ点数です。<br>\n気温が上がりキンキンに冷えたお酒が美味しい季節がやってきますが、飲みすぎには注意が必要！<a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">こちら</a>から、健康的なお酒の楽しみ方を考えましょう。\n\n"
    },
    "sleep": {
      "up": "睡眠のスコアは5月より%d点上がりました。<br>\n夕方以降のカフェイン摂取は睡眠の質低下につながります。カフェインはコーヒーだけでなく緑茶やエナジードリンクなど様々な飲み物に含まれているため、飲み過ぎには注意しましょう。\n\n",
      "down": "睡眠のスコアは5月より%d点下がりました。<br>\n夕方以降のカフェイン摂取は睡眠の質低下につながります。カフェインはコーヒーだけでなく緑茶やエナジードリンクなど様々な飲み物に含まれているため、飲み過ぎには注意しましょう。\n\n",
      "equal": "睡眠のスコアは5月と同じ点数です。<br>\n夕方以降のカフェイン摂取は睡眠の質低下につながります。カフェインはコーヒーだけでなく緑茶やエナジードリンクなど様々な飲み物に含まれているため、飲み過ぎには注意しましょう。\n\n"
    },
    "stress": {
      "up": "ストレスのスコアは5月より%d点上がりました。<br>\n暑い時こそ湯船に浸かりませんか？38～40℃のぬるめのお湯に10分程度浸かるだけでもリラックス効果が得られます。\n\n",
      "down": "ストレスのスコアは5月より%d点下がりました。<br>\n暑い時こそ湯船に浸かりませんか？38～40℃のぬるめのお湯に10分程度浸かるだけでもリラックス効果が得られます。\n\n",
      "equal": "ストレスのスコアは5月と同じ点数です。<br>\n暑い時こそ湯船に浸かりませんか？38～40℃のぬるめのお湯に10分程度浸かるだけでもリラックス効果が得られます。\n\n"
    }
  },
  "en": {
    "score": {
      "up": "Your lifestyle score has gone up by %d points since May. Which of the following items have you seen change?<br>\nIn July, many people suffer heat stroke because their bodies cannot keep up with the sudden rise in temperature. The basics of heat stroke prevention are <b>❝protection from the heat❞</b> and <b>❝frequent hydration.❞</b> Prevent heat stroke by using hats/parasols and drinking water as soon as possible, before you become thirsty!\n\n",
      "down": "Your lifestyle score has gone down by %d points since May. Which of the following items have you seen change?<br>\nIn July, many people suffer heat stroke because their bodies cannot keep up with the sudden rise in temperature. The basics of heat stroke prevention are <b>❝protection from the heat❞</b> and <b>❝frequent hydration.❞</b> Prevent heat stroke by using hats/parasols and drinking water as soon as possible, before you become thirsty!\n\n",
      "equal100": "Your lifestyle score is perfect, like it was in May. Excellent!<br>\nIn July, many people suffer heat stroke because their bodies cannot keep up with the sudden rise in temperature. The basics of heat stroke prevention are <b>❝protection from the heat❞</b> and <b>❝frequent hydration.❞</b> Prevent heat stroke by using hats/parasols and drinking water as soon as possible, before you become thirsty!\n\n",
      "equal": "Your lifestyle score is the same as it was in May. <br>\nIn July, many people suffer heat stroke because their bodies cannot keep up with the sudden rise in temperature. The basics of heat stroke prevention are <b>❝protection from the heat❞</b> and <b>❝frequent hydration.❞</b> Prevent heat stroke by using hats/parasols and drinking water as soon as possible, before you become thirsty!\n\n"
    },
    "steps": {
      "up": "Your steps score has gone up %d points since May. This is a wonderful result during a time of year when there is a lot of rain!<br>\nHow do you like the effects of the Team Walk Competition 2024 1st? Keep up the good habit by referring to <a href=\"https://go.andwell.jp/library/tag/9d2a9468-5e2f-11ec-9ce8-0af363eb5062\">this page</a>!\n\n",
      "down": "Your steps score has gone down %d points since May. <br>\nWhether you walk frequently or for long periods of time, the benefits of walking remain the same as long as the total time is the same. Why not use <a href=\"https://go.andwell.jp/library/tag/9d2a9468-5e2f-11ec-9ce8-0af363eb5062\">this page</a> to find tips on how to increase the number of steps you take?\n\n",
      "equal": "Your steps score is the same as it was in May. <br>\nWhether you walk frequently or for long periods of time, the benefits of walking remain the same as long as the total time is the same. Why not use <a href=\"https://go.andwell.jp/library/tag/9d2a9468-5e2f-11ec-9ce8-0af363eb5062\">this page</a> to find tips on how to increase the number of steps you take?\n\n"
    },
    "exercise": {
      "up": "Your exercise score has gone up %d points since May.<br>\nExercise is an effective way to acclimate your body to the heat. Acclimation to the heat helps prevent heat stroke!\n\n",
      "down": "Your exercise score has gone down %d points since May.<br>\nExercise is an effective way to acclimate your body to the heat. Acclimation to the heat helps prevent heat stroke!\n\n",
      "equal": "Your exercise score is the same as it was in May. <br>\nExercise is an effective way to acclimate your body to the heat. Acclimation to the heat helps prevent heat stroke!\n\n"
    },
    "meal": {
      "up": "Your food score has gone up %d points since May.<br>\nWhen the temperature rises, we tend to choose cold foods. However, eating too much cold food weakens the function of the gastrointestinal tract and causes a loss of appetite. It is recommended not to eat cold foods all at once, but to eat them little by little.\n\n",
      "down": "Your food score has gone down %d points since May.<br>\nWhen the temperature rises, we tend to choose cold foods. However, eating too much cold food weakens the function of the gastrointestinal tract and causes a loss of appetite. It is recommended not to eat cold foods all at once, but to eat them little by little.\n\n",
      "equal": "Your food score is the same as it was in May. <br>\nWhen the temperature rises, we tend to choose cold foods. However, eating too much cold food weakens the function of the gastrointestinal tract and causes a loss of appetite. It is recommended not to eat cold foods all at once, but to eat them little by little.\n\n"
    },
    "drinking": {
      "up": "Your alcohol score has gone up %d points since May.<br>\nThe season is coming when the temperature is rising and the drinks are deliciously chilled, but we need to be careful not to drink too much! <a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">Here</a> are some healthy ways to enjoy alcoholic beverages.\n\n",
      "down": "Your alcohol score has gone down %d points since May.<br>\nThe season is coming when the temperature is rising and the drinks are deliciously chilled, but we need to be careful not to drink too much! <a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">Here</a> are some healthy ways to enjoy alcoholic beverages.\n\n",
      "equal": "Your alcohol score is the same as it was in May. <br>\nThe season is coming when the temperature is rising and the drinks are deliciously chilled, but we need to be careful not to drink too much! <a href=\"https://go.andwell.jp/library/tag/9ef4cd22-5e2f-11ec-9ce8-0af363eb5062\">Here</a> are some healthy ways to enjoy alcoholic beverages.\n\n"
    },
    "sleep": {
      "up": "Your sleep score has gone up %d points since May.<br>\nConsuming caffeine later in the evening can lead to poor sleep quality. Caffeine is found not only in coffee, but also in green tea, energy drinks, and many other beverages, so be careful not to drink too much.\n\n",
      "down": "Your sleep score has gone down %d points since May.<br>\nConsuming caffeine later in the evening can lead to poor sleep quality. Caffeine is found not only in coffee, but also in green tea, energy drinks, and many other beverages, so be careful not to drink too much.\n\n",
      "equal": "Your sleep score is the same as it was in May. <br>\nConsuming caffeine later in the evening can lead to poor sleep quality. Caffeine is found not only in coffee, but also in green tea, energy drinks, and many other beverages, so be careful not to drink too much.\n\n"
    },
    "stress": {
      "up": "Your stress score has gone up %d points since May.<br>\nSoaking in a hot tub in hot weather, even a 10-minute soak in lukewarm water (38-40°C), can have a relaxing effect.\n\n",
      "down": "Your stress score has gone down %d points since May.<br>\nSoaking in a hot tub in hot weather, even a 10-minute soak in lukewarm water (38-40°C), can have a relaxing effect.\n\n",
      "equal": "Your stress score is the same as it was in May. <br>\nSoaking in a hot tub in hot weather, even a 10-minute soak in lukewarm water (38-40°C), can have a relaxing effect.\n\n"
    }
  }
}